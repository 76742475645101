import React from 'react';
import logo from "../../assets/images/Livemarket-Trade.png";


const Logo = () => {
  return (
    <div>
        <div>
            <img className='w-40' src={logo} alt="livemarket-trade" />
        </div>
    </div>
  )
}

export default Logo;